<template>
	<div>
		<mobile-header active="/mobile/aboutUs"></mobile-header>
		<div style="display: flex;text-align: center;">
			<div style="flex: 1;">
				<p class="font3">{{list[0].title }}</p>
				<p class="desc" v-html="list[0].desp">
				</p>
			</div>
			<div style="flex: 1;">
				<p class="font3">{{list[1].title }}</p>
				<p class="desc" v-html="list[1].desp">
				</p>
			</div>
		</div>
		<div style="display: flex;text-align: center;">
			<div style="flex: 1;">
				<p class="font3">{{list[2].title }}</p>
				<div align="center">
					<p class="desc" v-html="list[2].desp">
					</p>
				</div>
			</div>
			<div style="flex: 1;">
				<h2 class="font3">{{list[3].title }}</h2>
				<p class="desc" v-html="list[3].desp">
				</p>
			</div>
		</div>

		<div style="text-align: center;margin: 40px 0 0 0;">
			<h4 style="margin-bottom: 0;" class="font2">公司业务</h4>
			<p class="borderBottom"><span>&nbsp;</span></p>
			<div style="display: flex;">
				<div style="flex: 1;text-align: center;" v-for="(item,index) in yewu.slice(0,2)" :key="index">
					<img :src="item.src" style="width: 80%;">
					<h5 style="margin:5px 0;">{{item.title}}</h5>
				</div>
			</div>
			<div style="display: flex;margin-top: 15px;">
				<div style="flex: 1;text-align: center;" v-for="(item,index) in yewu.slice(2,4)" :key="index">
					<img :src="item.src" style="width: 80%;">
					<h5 style="margin:5px 0;">{{item.title}}</h5>
				</div>
			</div>
		</div>

		<div style="text-align: center;margin: 40px 0 0 0;">
			<h4 style="margin-bottom: 0;" class="font2">服务优势</h4>
			<p class="borderBottom"><span>&nbsp;</span></p>
			<div style="display: flex;">
				<div style="flex: 1;text-align: center;" v-for="(item,index) in youshi.slice(0,2)" :key="index">
					<img :src="item.src" style="width: 80%;">
					<h5 style="margin:5px 0;">{{item.title}}</h5>
				</div>
			</div>
			<div style="display: flex;margin-top: 15px;">
				<div style="flex: 1;text-align: center;" v-for="(item,index) in youshi.slice(2,4)" :key="index">
					<img :src="item.src" style="width: 80%;">
					<h5 style="margin:5px 0;">{{item.title}}</h5>
				</div>
			</div>
		</div>

		<div style="margin: 40px 0 0 0;">
			<h4 style="margin-bottom: 0;" class="font2">荣誉资质</h4>
			<p class="borderBottom"><span>&nbsp;</span></p>
			<div class="wrap">
					<ul class="list">
						<li v-for="(item,index) in zizhi" :key="index"><img :src="item.src"> </li>
					</ul>
			</div>
		</div>

		<div style="margin: 40px 10px 0 10px;">
			<h4 style="margin-bottom: 0;" class="font2">专家顾问</h4>
			<p class="borderBottom"><span>&nbsp;</span></p>
			<el-carousel indicator-position="none" ref="carousel" :autoplay="true" height="240px" :interval="10000">
				<el-carousel-item v-for="item in pro_card" :key="item.id">
					<div style="padding:10px 0 10px 10px;">
						<img :src="item.src" style="border-radius: 50%;
							height:70px;
							margin-left: 0px;
							box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
							vertical-align: middle;
						" />
						<p style="display: inline-block;vertical-align: middle;padding-left:10px;">
							<span style="color:#0084C4;font-size: 15px;">{{ item.name }}</span><br>
							<span style="color: #595959;font-size: 13px;">{{ item.title }}</span>
						</p>
						<p style="font-size: 12px;margin-top: 5px;color: #595959;line-height: 20px;">
							{{ item.desp }}
						</p>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
	</div>
</template>

<style scoped>
	.font2 {
		background-image: -webkit-gradient(linear, left top, right top, color-stop(0.3, #22f), color-stop(0.6, #2ff));
		color: transparent;
		-webkit-background-clip: text;
		text-align: center;
	}

	.font3 {
		background-image: -webkit-gradient(linear, left top, right top, color-stop(0.3, #22A8FF), color-stop(0.6, #2ff));
		color: transparent;
		-webkit-background-clip: text;
		font-weight: 600;
		font-size: 20px;
		margin: 5px 0;
	}

	.borderBottom {
		text-align: center;
		margin: 0 0 10px 0;
	}

	.borderBottom span {
		border-bottom: #FF8700 3px solid;
		display: inline-block;
		width: 20px;
	}

	.desc {
		color: #606266;
		font-size: 13px;
		margin-top: 0;
	}
	.wrap {
		overflow: hidden;
		width: 100%;
		/* 400+444+503.5+443+400+212*3+216+207.5*2+446+400 */
		height: 152px;
		margin: 25px auto;
	}
	
	.wrap * {
		margin: 0;
		padding: 0;
	}
	
	.list {
		position: relative;
		top: 0px;
		left: 0px;
		width: 4303.5px;
		height: 100%;
		list-style: none;
		animation: mymove 50s infinite linear;
	}
	
	.list li {
		width: auto;
		height: 150px;
		border: 1px solid #FFFFFF;
		float: left;
		line-height: 150px;
	}
	
	.list img {
		height: 100%;
		width: auto;
	}
	
	.list:hover {
		animation-play-state: paused;
	}
	@-webkit-keyframes mymove {
		0% {
			transform: translateX(0px);
		}
	
		4% {
			transform: translateX(0px);
		}
	
		6% {
			transform: translateX(-202px);
		}
	
		13% {
			transform: translateX(-202px);
		}
	
		15% {
			transform: translateX(-426px);
		}
	
		22% {
			transform: translateX(-426px);
		}
	
		24% {
			transform: translateX(-679.75px);
		}
	
		31% {
			transform: translateX(-679.75px);
		}
	
		33% {
			transform: translateX(-903.25px);
		}
	
		39% {
			transform: translateX(-903.25px);
		}
	
		41% {
			transform: translateX(-1105.25px);
		}
	
		47% {
			transform: translateX(-1105.25px);
		}
	
		48% {
			transform: translateX(-1213.25px);
		}
	
		54% {
			transform: translateX(-1213.25px);
		}
	
		55% {
			transform: translateX(-1321.25px);
		}
	
		61% {
			transform: translateX(-1321.25px);
		}
	
		62% {
			transform: translateX(-1429.25px);
		}
	
		68% {
			transform: translateX(-1429.25px);
		}
	
		69% {
			transform: translateX(-1539.25px);
		}
	
		75% {
			transform: translateX(-1539.25px);
		}
	
		76% {
			transform: translateX(-1645px);
		}
	
		82% {
			transform: translateX(-1645px);
		}
	
		83% {
			transform: translateX(-1750.25px);
		}
	
		89% {
			transform: translateX(-1750.25px);
		}
	
		91% {
			transform: translateX(-1975.75px);
		}
	
		97% {
			transform: translateX(-1975.75px);
		}
	
		100% {
			transform: translateX(-2177.75px);
		}
	}
</style>
<script>
	import mobileHeader from "@components/mobileHeader";
	export default {
		name:'aboutUs',
		metaInfo: {
			title: '关于我们',
			meta: [{
					name: 'keyWords',
					content: '投标代理,招投标咨询,招投标培训,招投标专题研究,招标审计'
				},
				{
					name: 'description',
					content: '招投研究院（广州）有限公司是一家专注于招标代理、招投标咨 询培训整体解决方案的专业服务机构。研究院注册资金 1200 万元，在广州海珠区新港东路琶洲酒店写字楼、南沙进港大道中环广场设有开评标办公室、在深圳罗湖设立分公司，办公面积760方。'
				}
			]
		},
		components: {
			mobileHeader,
		},
		data() {
			return {
				src: require("@assets/image/公司前台.jpg"),
				list: [{
						id: 1,
						title: "使命",
						desp: "让招投标结果更完美",
					},
					{
						id: 2,
						title: "愿景",
						desp: "集科技和智慧、<br>服务千万招投人",
					},
					{
						id: 3,

						title: "价值观",
						desp: "提升绩效、规范管理<br>提高技能、降低风险",
					},
					{
						id: 4,

						title: "工作准则",
						desp: "严守秘密、成就客户<br>专业精益、积极诚信",
					},
				],
				yewu: [{
						id: 1,
						src: require("@assets/images/aboutUs/关于我们—招标代理.jpg"),
						title: "招标代理",
						desp: "招投研究院具有独立编制有关招标文件、有效组织评标活动的专业团队和技术设施，具有多年从事招标代理业务的经验，不但为招标人提供合法合规、专业高效的服务，而且能为招标人解决很多招标采购中的棘手问题。",
					},
					{
						id: 2,
						src: require("@assets/images/aboutUs/关于我们—培训.jpg"),
						title: "招投标咨询培训",
						desp: "招投研究院具有专业的招投标咨询专家团队。他们具有丰富的招投标理论知识和实践经验，应用科学、前沿的方法，找出企业在招投标管理方面存在的问题，分析原因，提出改进方案，并指导企业将改进方案实施落地，改善企业的招投标管理水平。",
					},
					{
						id: 3,
						src: require("@assets/images/aboutUs/关于我们—专题研究.jpg"),
						title: "招投标专题研究",
						desp: "目前承接的招投标专题研究业务有智能辅助评标、投标人画像、围标串标等，通过专注某个专题的研究，为客户提供更深层次的专业解决方案，提升客户招投标管理能力与效率，降低招投标管理风险。",
					},
					{
						id: 4,
						src: require("@assets/images/aboutUs/审计.jpg"),
						title: "招标审计",
						desp: "招投研究院具有专业的招标审计团队，通过对招投标制度和流程、招投标实施过程、招标方案及相关文件进行审查，从合法性、规范性层面提出整改建议。",
					},
				],
				youshi: [{
						id: 1,
						src: require("@assets/image/实力雄厚.jpg"),
						title: "专业可靠的服务经验",
						desp: "我们为客户提供专业可靠的招投标服务，每一位招投标顾问都有着专业的招投标服务经验",
					},
					{
						id: 2,
						src: require("@assets/image/专业团队.jpg"),
						title: "实力雄厚的企业基础",
						desp: "我们有着华南地区专业领域突出的招投标服务团队，我们的服务对象有着雄厚的实力",
					},
					{
						id: 3,
						src: require("@assets/image/良好口碑.jpg"),
						title: "引领行业的企业目标",
						desp: "科技在不断发展，在互联网思维的指引下，我们的服务也在不断创新，引领行业前列",
					},
					{
						id: 4,
						src: require("@assets/image/经验丰富.jpg"),
						title: "良好的信誉口碑",
						desp: "将顾客当上帝不仅是说说而已，每一次对客户的服务，我们都会全力以赴",
					},
				],
				zizhi: [
					{
							id: 1,
							src: require("@assets/images/aboutUs/1AAA级信用企业.jpg"),
						},
						{
							id: 2,
							src: require("@assets/images/aboutUs/2中国招标投标协会会员单位.png"),
						},
						{
							id: 3,
							src: require("@assets/images/aboutUs/3广东省公共资源交易联合会理事单位.png"),
						},
						{
							id: 4,
							src: require("@assets/images/aboutUs/4广东省政府采购协会第二届会员单位.png"),
						},
						{
							id: 5,
							src: require("@assets/images/aboutUs/5大学生社会实践基地.jpg"),
						},
						{
							id: 6,
							src: require("@assets/images/aboutUs/6质量管理体系认证证书.jpg"),
						},
						{
							id: 7,
							src: require("@assets/images/aboutUs/7安全管理体系认证证书.jpg"),
						},
						{
							id: 8,
							src: require("@assets/images/aboutUs/8环境管理体系认证证书.jpg"),
						},
						{
							id: 9,
							src: require("@assets/images/aboutUs/9广东省公共资源交易现代科学信息技术应用与创新二等奖.png"),
						},
						{
							id: 10,
							src: require("@assets/images/aboutUs/10重合同守信用企业证书1.png"),
						},
						{
							id:11,
							src: require("@assets/images/aboutUs/11重合同守信用企业证书2.png"),
						},
						{
							id: 12,
							src: require("@assets/images/aboutUs/12广东省政府采购代理机构行业自律公约成员单位.png"),
						},
						{
							id: 13,
							src: require("@assets/images/aboutUs/13中国招投标协会会员.jpg"),
						},
						{
								id: 1,
								src: require("@assets/images/aboutUs/1AAA级信用企业.jpg"),
							},
							{
								id: 2,
								src: require("@assets/images/aboutUs/2中国招标投标协会会员单位.png"),
							},
							{
								id: 3,
								src: require("@assets/images/aboutUs/3广东省公共资源交易联合会理事单位.png"),
							},
							{
								id: 4,
								src: require("@assets/images/aboutUs/4广东省政府采购协会第二届会员单位.png"),
							},
							{
								id: 5,
								src: require("@assets/images/aboutUs/5大学生社会实践基地.jpg"),
							},
							{
								id: 6,
								src: require("@assets/images/aboutUs/6质量管理体系认证证书.jpg"),
							},
							{
								id: 7,
								src: require("@assets/images/aboutUs/7安全管理体系认证证书.jpg"),
							},
							{
								id: 8,
								src: require("@assets/images/aboutUs/8环境管理体系认证证书.jpg"),
							},
							{
								id: 9,
								src: require("@assets/images/aboutUs/9广东省公共资源交易现代科学信息技术应用与创新二等奖.png"),
							},
							{
								id: 10,
								src: require("@assets/images/aboutUs/10重合同守信用企业证书1.png"),
							},
							{
								id:11,
								src: require("@assets/images/aboutUs/11重合同守信用企业证书2.png"),
							},
							{
								id: 12,
								src: require("@assets/images/aboutUs/12广东省政府采购代理机构行业自律公约成员单位.png"),
							},
							{
								id: 13,
								src: require("@assets/images/aboutUs/13中国招投标协会会员.jpg"),
							},
				],
				pro_card: [{
						id: 1,
						src: require("@assets/image/刘春胜.png"),
						name: "刘春胜",
						title: "招投标管理专家",
						desp: "招投研究院首席顾问。暨南大学法学硕士、注册招标师、华南理工大学工程硕士校外指导导师、政府采购评标专家、南沙企业联合会智库专家等。作为招投标管理专家，数十家大型公司招投标常年管理顾问，每年300多家企事业单位提供了招投标培训咨询服务，得到众多企业的高度认可。",
					},
					{
						id: 2,
						src: require("@assets/image/曾敏刚.jpg"),
						name: "曾敏刚",
						title: "供应链管理专家",
						desp: "博士，副教授，招投研究院高级顾问，研究方向为供应链整合、物流系统规刻、逆向物流、应急物流等。在中国管理科学、工业工程与管理、工业工程等核心期刊发表论文30余篇（其中10余篇被三大索引收录），荣获教育部、学校等各级奖励（其中省部级奖励 2 项），个人已主持 20 余项研究项目（其中省部级项目 8 项），参加 2 项国家重点研究项目。",
					},
					{
						id: 3,
						src: require("@assets/image/陈伟.jpg"),
						name: "陈伟",
						title: "招投标专家",
						desp: "著名实战派招投标专家，招投研究院特聘高级讲师。中国企业联合会培训委员会执行委员、中国招投标教育培训中心首席专家，中国工业品营销招投标研究所所长，“最有影响力培训师“、“最佳招投标讲师”称号获得者、招投标专业“十佳培训师”、中国智慧产业与知识经济联合会“中国十佳招投标讲师”、中国企业管理能力建设工程“特聘专家”、《中国招标》周刊社“特聘专家讲师”。",
					},
					{
						id: 4,
						src: require("@assets/image/崔桂秋.jpg"),
						name: "崔桂秋",
						title: "政府采购专家",
						desp: "曾在广东省财政厅政府采购监管处任调研员，从2005年开始从事政府采购管理工作，长期组织和参与各种重大政府采购项目的投诉处理。因对政府采购法律法规具有深入研究和长期实践，深受政府采购管理部门的认可，近年来受邀为全省各市县政府采购中心和省直机关授课150余场，是广东省乃至全国政府釆购管理的权威专家。",
					},
					{
						id: 5,
						src: require("@assets/image/冯寸生.jpg"),
						name: "冯寸生",
						title: "招投标法律专家",
						desp: "暨南大学经济法学硕士，广东正大方略律师事务所合伙人。主要从业经验集中于建筑、招投标、基础设施建设项目、TOD项目、PPP项目、房地产、公司等非诉讼及诉讼法律服务领域。对建筑、房地产、招投标、基础设施建设项目、TOD项目、PPP项目、政府行政法律服务领域，常年为广东省文化厅、广州市黄埔区人民政府；广州市黄埔区农林水利局等政府事业单位提供法律咨询服务。",
					},
				],
			};
		},
		mounted: function() {
			this.bus.$emit('headerImg', [{
				src: require('@/assets/images/bk/关于我们Mobile.jpg'),
				href: ""
			}]);
		}
	};
</script>
